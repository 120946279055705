import {
  Breadcrumb,
  BreadcrumbHint,
  captureException,
  captureMessage,
  ErrorBoundary,
  Event,
  EventHint,
  init,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AxiosError } from 'axios';
import { FunctionComponent } from 'react';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';

import { NODE_ENV, SENTRY_DSN } from '@/config';
import { SomethingWrong } from '@/features/misc';

export const sentryCaptureException = captureException;
export const sentryCaptureMessage = captureMessage;

const initErrorLogging = (routingInstrumentation: any) => {
  initializeSentry(routingInstrumentation);
};

const beforeBreadcrumb = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => {
  if (breadcrumb.category === 'ui.click' && hint?.event.target.id) {
    breadcrumb.message = hint?.event.target.id;
  }
  if (breadcrumb.category === 'ui.input' && hint?.event.target.type) {
    breadcrumb.message = hint?.event.target.type;
  }

  return breadcrumb;
};

const beforeSend = (event: Event, hint: EventHint) => {
  const exception = hint.originalException;

  if (exception && exception instanceof AxiosError) {
    const message = exception.response?.data.message as string;
    const status = String(exception.response?.status);
    const url = String(exception.response?.config.url);

    if (message === 'Unauthenticated.') return null;

    event.message = message;
    event.fingerprint = [status, url, message];
  }

  return event;
};

const initializeSentry = (routingInstrumentation: any): void => {
  const browserTracing = new BrowserTracing({
    routingInstrumentation,
  });

  return init({
    dsn: SENTRY_DSN,
    integrations: [browserTracing],
    tracesSampleRate: 0.3,
    release: '2.6.4',
    beforeBreadcrumb,
    beforeSend,
  });
};

export const SentryErrorBoundary: FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
  const routingInstrumentation = useRoutingInstrumentation();

  if (NODE_ENV === 'production') initErrorLogging(routingInstrumentation);

  return (
    <ErrorBoundary fallback={({ error }) => <SomethingWrong errorCode={error.name} />}>
      {children}
    </ErrorBoundary>
  );
};
